html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Disable window scrolling */
}
body {
  font-family: 'Roboto', sans-serif; /* Privzeta pisava */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif; /* Naslovi */
}

p {
  font-family: 'Open Sans', sans-serif; /* Besedilo */
}

button {
  font-family: 'Lato', sans-serif; /* Gumbi */
}


/* Ensure no horizontal scrolling on the main container */
.chat-container {
  overflow-x: hidden;
}

#root {
  height: 100%;
}