.welcome-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  overflow-y: auto;
}

.welcome-modal {
  background: white;
  padding: 30px 25px 25px 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-modal h2 {
  margin: 0px 5px 10px 5px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.welcome-modal p {
  margin: 5px 5px 5px 5px;
  font-size: 16px;
  color: #777;
  text-align: left;
}

.welcome-modal h5 {
  margin: 5px 5px 5px 5px;
  font-size: 14px;
  text-align: center;
}

.welcome-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-modal-button {
  background-color: #D7B56F;
  color: white;
  border: none;
  padding: 10px 100px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 10px;
}

.avatar-modal {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: white;
  padding: 3px;
}

.language-switcher-modal {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.language-switcher-modal .lang-button-modal {
  background-color: white;
  color: #282828;
  border: 2px solid #d7b56f;
  padding: 5px 10px;
  margin: 5px 5px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.language-switcher-modal .lang-button-modal:hover,
.language-switcher-modal .lang-button-modal.active {
  background-color: #d7b56f;
  color: white;
}

.flag-icon-modal {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
  border: 2px solid #D7B56F; /* Barva okvirja */
}

.welcome-modal-checkbox {
  margin-top: 10px;
  font-size: 14px;
  color: #777;
}

.welcome-modal-checkbox label {
  display: flex;
  align-items: center;
}

.welcome-modal-checkbox input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #d7b56f; /* Barva okvirja checkboxa */
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  background-color: white; /* Ensure white background */
  margin-right: 5px;
}

.welcome-modal-checkbox input[type="checkbox"]:checked {
  background-color: #d7b56f; /* Barva ozadja obkljukanega checkboxa */
}

.welcome-modal-checkbox input[type="checkbox"]:checked::after {
  content: '\2713'; /* Unicode for checkmark */
  font-size: 12px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.welcome-modal p a {
  color: #D7B56F;
  text-decoration: underline;
}

.welcome-modal p a:hover {
  text-decoration: underline;
}
