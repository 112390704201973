body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
  }

/* Skrije defaultno deljenje gumba na iPhone-u v Safariju */
body::-webkit-scrollbar {
  display: none;
}
  
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f0f0;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .start-new-chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: white;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .start-new-chat-button {
    background-color: #D7B56F;
    color: white;
    border: none;
    padding: 15px 100px;
    margin: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .start-new-chat-button:hover {
    background-color: #B59548;
  }
  
  .chat-footer {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: white;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .question-form {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .question-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    padding-right: 40px;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
  }
  
  .question-input::placeholder {
    font-family: 'Arial', sans-serif;
  }
  
  .submit-button {
    position: absolute;
    right: 10px;
    background-color: #D7B56F;
    color: white;
    border: none;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    font-size: 13px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .submit-button i {
    font-size: 14px;
  }
  
  .submit-button:disabled {
    opacity: 0;
    pointer-events: none;
  }
  
  .submit-button:enabled {
    opacity: 1;
    pointer-events: auto;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
  }