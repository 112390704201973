.subpage {
  background-color: #f0f0f0;
}

.subpage-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 0;
  background-color: #f0f0f0;
  font-family: 'Arial', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subpage-content {
  padding: 120px 30px 120px 30px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

.subpage-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.subpage-content .content-section {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; /* Align items in column */
  align-items: center; /* Center align items */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left; /* Center text */
}

.subpage-content .content-icon {
  font-size: 60px; /* Increase icon size */
  margin-bottom: 10px; /* Add margin below icon */
  color: #D7B56F;
}

.subpage-content .content-icon-privacy {
  font-size: 40px; /* Increase icon size */
  margin-bottom: 10px; /* Add margin below icon */
  color: #D7B56F;
}

.subpage-content h3 {
  font-size: 20px;
  margin: 10px 0; /* Add margin above and below heading */
  text-align: center;
}

.subpage-content p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.subpage-content a {
  color: #D7B56F;
}

.subpage-content ul {
  list-style-type: disc;
  margin: 20px 0;
  padding-left: 20px;
  text-align: left;
}

/* Add this class to support multiline text with proper formatting */
.multiline-text {
  white-space: pre-line;
}
