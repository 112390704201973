.chat-history {
  width: 100%;
  max-width: 600px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden; 
  background-color: #ffffff;
  padding: 20px 10px 80px 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* margin-bottom: 60px; */
  margin-top: 60px; 
}

.assistant-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 20px;
  border-bottom: none; /* Remove bottom border */
  box-sizing: border-box; /* Include padding in width calculation */
}

.assistant-info img {
  border-radius: 50%;
  width: 120px;
  height: 80px;
  background-color: white;
  padding: 10px;
}

.assistant-info h2 {
  margin: 15px 0 5px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.assistant-info p {
  margin: 0;
  font-size: 18px;
  color: #777;
  text-align: center;
}

.chat-message {
  padding: 10px 0;
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
}

.chat-message.user {
  justify-content: flex-end;
  text-align: right;
}

.chat-message.assistant {
  justify-content: flex-start;
  text-align: left;
}

.chat-message .avatar {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #f1f0f0;
  padding: 3px;
  margin-left: 8px; /* Reduce margin between avatar and message */
  align-self: flex-end; /* Align avatar to the bottom */
}

.chat-message.user .avatar {
  display: none;
}

.chat-message .message-content {
  max-width: 80%;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 16px;
  margin-left: 10px; /* Add margin for aesthetic spacing */
  margin-right: 10px; /* Add margin for aesthetic spacing */
}

.chat-message .message-content a {
  color: #D7B56F; /* Zamenjajte z barvo, ki jo želite za povezave */
  text-decoration: underline; /* To bo podčrtalo povezave, če želite */
}

.chat-message .message-content a:hover {
  color: #B59548; /* Temnejša barva za povezave ob prehodu z miško */
}

.chat-message.user .message-content {
  background-color: #D7B56F; /* Updated color */
  color: #ffffff;
  border-bottom-right-radius: 0;
  text-align: left;
}

.chat-message.assistant .message-content {
  background-color: #f1f0f0;
  color: #333;
  border-bottom-left-radius: 0;
}

.chat-input-container {
  display: flex;
  width: 100%;
  max-width: 600px;
  padding: 10px 10px;
  border-top: none; /* Remove top border */
  box-sizing: border-box; /* Include padding in width calculation */
  position: fixed; /* Change to fixed */
  bottom: 0;
  background-color: white;
  margin: 0 auto;
}

.chat-input-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box; /* Include padding in width calculation */
}

.chat-input-container button {
  padding: 10px 10px; /* Add padding for spacing */
  background-color: #D7B56F; /* Updated color */
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.chat-input-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button {
  transition: opacity 0.3s ease-in-out;
}

.chat-message.assistant .message-content {
  background-color: #f1f0f0;
  color: #333;
  border-bottom-left-radius: 0;
}

.chat-message .message-content ul,
.chat-message .message-content ol {
  padding-left: 20px; /* Indent lists */
  margin: 10px 0;
}

.chat-message .message-content li {
  margin-bottom: 5px;
}

.chat-message .message-content p {
  margin: 8px 0;
}

.chat-message .message-content strong {
  font-weight: bold;
}

.chat-message .message-content em {
  font-style: italic;
}

.chat-message .message-content h1 {
  font-size: 20px;
  margin: 8px 0;
}

.chat-message .message-content h2 {
  font-size: 18px;
  margin: 8px 0;
}

.chat-message .message-content h3,
.chat-message .message-content h4,
.chat-message .message-content h5,
.chat-message .message-content h6 {
  font-size: 16px;
  margin: 8px 0;
}

/* Table Styling */
.chat-message .message-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.chat-message .message-content table th,
.chat-message .message-content table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.chat-message .message-content table th {
  background-color: #f9f9f9;
  font-weight: bold;
}