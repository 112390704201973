.chat-header-subpage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #D7B56F;
  color: white;
  padding: 15px 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.chat-header-subpage h2 {
  font-size: 18px;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.chat-header-subpage .button-subpage {
  font-size: 24px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.chat-header-subpage .button-subpage:focus {
  outline: none;
}
