.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #D7B56F;
    color: white;
    padding: 10px 0px 10px 0px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .chat-header h2 {
    font-size: 20px;
    margin: 0;
    flex-grow: 1;
    text-align: left;
    padding-left: 20px;
  }

  .chat-header .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-left: 20px;
  }

  .menu-button {
    font-size: 24px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }