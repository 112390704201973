.recommendations-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0px 60px 0px;
    background-color: white; /* Enoten background */
  }
  
  .recommendation-button {
    background: white;
    color: black;
    border: 0.5px solid #d7b56f;
    padding: 8px 7px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    width: calc(50% - 20px);
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s; /* Dodajte prehod za gladko spreminjanje barve */
  }
  
  .recommendation-button strong {
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
  }
  
  .recommendation-button span {
    display: block;
    font-size: 12px;
    color: #777;
  }
  
  .recommendation-button:hover {
    background: #d7b56f;
    color: white;
  }
  
  .recommendation-button:hover strong,
  .recommendation-button:hover span {
    color: white; /* Pobelite oba teksta, ko je gumb hoveran */
  }
  