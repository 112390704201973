.menu-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.menu-sidebar {
    width: 100%;
    max-width: 600px;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #d7b56f;
    color: white;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
}

.menu-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.menu-title {
    font-size: 20px;
    margin: 0;
    flex-grow: 1;
    text-align: left;
    padding-left: 20px;
    cursor: pointer;
}

.menu-close-button {
    padding-right: 10px;
    font-size: 28px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.menu-options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 60px 30px 30px 30px;
    font-family: 'Arial', sans-serif;
}

.menu-button-option {
    background: none;
    padding: 10px;
    margin: 10px auto;
    width: calc(100% - 60px);
    text-align: left;
    font-size: 20px;
    color: #282828;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
    border: 2px solid #d7b56f;
    border-radius: 10px;
}

.menu-button-option i {
    margin-right: 15px;
}

.menu-button-option:hover,
.menu-button-option:focus {
    background: #d7b56f;
    color: white;
    border: 2px solid #fff; /* Dodajte rob za boljšo vizualno ločitev */
}

.menu-button-option.active {
    background: #d7b56f;
    color: white;
    border: 2px solid #fff; /* Dodajte rob za boljšo vizualno ločitev */
}

.menu-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    background: #f0f0f0;
}

.menu-footer img {
    max-width: 100px;
    height: auto;
    cursor: pointer;
}

.language-switcher {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.language-switcher .lang-button {
    background-color: #f0f0f0;
    color: #282828;
    border: 2px solid #d7b56f;
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}

.language-switcher .lang-button:hover {
    background-color: #d7b56f;
    border: 2px solid #fff; /* Dodajte rob za boljšo vizualno ločitev */
    color: white;
}

.language-switcher .lang-button.active {
    background-color: #d7b56f; /* Označite aktivni jezik z drugačno barvo */
    border: 2px solid #fff; /* Dodajte rob za boljšo vizualno ločitev */
    color: white;
}

.flag-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    border: 2px solid #D7B56F; /* Barva okvirja */
}